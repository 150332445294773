import type {IncludedItem, PartnerAttributes} from '@pages/api/home';
import {
	Box,
	Image,
	LoadingSpinner,
	useColorMode,
	type ImageProps,
} from '@tempo/core';
import React from 'react';
import {useHomeData} from '@hooks/useHomeData';

export const PartnerLogo: React.FC<Omit<ImageProps, 'src' | 'alt'>> = ({
	...imgProps
}) => {
	const {colorMode} = useColorMode();
	const {data, isLoading} = useHomeData();
	const partnerAttributes = data?.included?.find(
		(i): i is IncludedItem & {attributes: PartnerAttributes} =>
			i.type === 'Partner',
	)?.attributes;
	const {name, logoBlackBg, logoWhiteBg} = partnerAttributes || {};

	return (
		<Box minHeight="32px" data-testid="partner-logo" {...imgProps}>
			{isLoading ? (
				<LoadingSpinner size={32} />
			) : (
				<Image
					src={colorMode === 'light' ? logoWhiteBg : logoBlackBg}
					alt={name || 'Partner logo'}
				/>
			)}
		</Box>
	);
};

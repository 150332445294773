import type {HomeResponseData} from '@pages/api/home';
import {useQuery} from '@tanstack/react-query';

export const useHomeData = () => {
	const getHomeData = async (): Promise<HomeResponseData> => {
		const response = await fetch('/api/home');
		if (!response.ok) {
			throw new Error('Failed to fetch partner data');
		}
		return await response.json();
	};

	return useQuery({
		queryKey: ['home'],
		queryFn: getHomeData,
		staleTime: 24 * 60 * 60 * 1000,
	});
};
